define(['app'], function(app) {
  
  function ProductAdvisor(productAdvisorForm) {

    this.questions = productAdvisorForm.querySelectorAll('.js-productAdvisorQuestion');
    this.questionInputs = productAdvisorForm.querySelectorAll('.js-productAdvisorInput');
    this.allAnswers = productAdvisorForm.querySelectorAll('.js-productAdvisorAnswer');
    this.questionResposes = productAdvisorForm.querySelectorAll('.js-productAdvisorResponse');
    this.backButton = productAdvisorForm.querySelector('.js-productAdvisorBack');
    this.productAdvisorTab = productAdvisorForm.querySelectorAll('.js-productAdvisorTab');
    this.productAdvisorButton = productAdvisorForm.querySelectorAll('.js-productAdvisorButton');

    var self = this;

    app.on('submit', function(e) {

      e.preventDefault();

      if (self.validateAnswers(self.questions)) {

        for (var i = 0; i < self.questions.length; i++) {

          var answers = self.questions[i].querySelectorAll('.js-productAdvisorAnswer');

          self.updateResponse(answers);

        }

        self.toggle(self.questionInputs, 'hide');
        self.toggle(self.allAnswers, 'response');
        self.toggle(self.productAdvisorTab, 'active');
        self.toggle(self.productAdvisorButton, 'hide');
        app.element.scrollTo(productAdvisorForm, 500);

      } else {
        alert('Oops! You need to make sure that you have filled in all the questions!');
      }

    }, productAdvisorForm);

    app.on('click', function(e) {
      e.preventDefault();

      self.hideResponses(self.questionResposes);
      self.toggle(self.questionInputs, 'hide');
      self.toggle(self.allAnswers, 'response');
      self.toggle(self.productAdvisorTab, 'active');
      self.toggle(self.productAdvisorButton, 'hide');
      app.element.scrollTo(productAdvisorForm, 500);

    }, self.backButton);

  }

  ProductAdvisor.prototype.validateAnswers = function(questions) {
    var result = 0;
    for (var i = 0; i < questions.length; i++) {
      if (questions[i].querySelectorAll('input:checked').length) {
        result++;
      }
    }
    return result >= questions.length;
  };

  ProductAdvisor.prototype.updateResponse = function(answers) {

    for (var j = 0; j < answers.length; j++) {

      var response = answers[j].querySelector('.js-productAdvisorResponse');

      if (response) {
        var hasClass = app.element.hasClass('hide', response);

        if (!hasClass) {
          app.element.addClass('hide', response);
        }

        if (answers[j].querySelector('input').checked) {
          app.element.removeClass('hide', response);
        }
      }
    }
  };

  ProductAdvisor.prototype.toggle = function(elements, className) {

    for (var i = 0; i < elements.length; i++) {

      var hasClass = app.element.hasClass(className, elements[i]);

      var classAPI = 'addClass';

      if (hasClass) {
        classAPI = 'removeClass';
      }

      app.element[classAPI](className, elements[i]);
    }

  };

  ProductAdvisor.prototype.hideResponses = function(questionResponses) {

    for (var i = 0; i < questionResponses.length; i++) {

      var hasClass = app.element.hasClass('hide', questionResponses[i]);

      if (!hasClass) {
        app.element.addClass('hide', questionResponses[i]);
      }
    }

  };

  var productAdvisorForm = document.querySelectorAll('[data-component=productAdvisor]');

  for (var i = 0, productAdvisorFormLength = productAdvisorForm.length; i < productAdvisorFormLength; i++) {
    new ProductAdvisor(productAdvisorForm[i]);
  }

  // Unit testing access
  return ProductAdvisor;

});

